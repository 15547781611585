import {
  FETCH_ITEMS_SUCCESS,
  FETCH_ITEMS_FAIL,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAIL,
  OVERWRITE_ITEM_SUCCESS,
  OVERWRITE_ITEM_FAIL,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAIL,
  DELETE_ITEM_FAIL,
  DELETE_ITEM_SUCCESS,
  CLEAR_ITEM_MSG,
  CLEAR_ITEM_FETCH_ERROR_MSG,
  FETCH_ITEMS_START,
  FETCH_CATALOGUE_TYPES_SUCCESS,
  FETCH_CATALOGUE_TYPES_FAIL,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAIL,
  FETCH_ITEM_SIZE_TYPES,
  FETCH_ITEM_SIZE_TYPES_FAIL,
  FETCH_ALL_ITEMS_START,
  FETCH_ALL_ITEMS_SUCCESS,
  FETCH_ALL_ITEMS_FAIL,
} from "./action-types";

const initialState = {
  loading: false,
  items: [] as any,
  totalCount: 0,
  errorMessage: null as null | string,
  successMessage: null as null | string,
  fetchErrorMessage: null as null | string,
  catalogues: [] as any,
  categories: [] as any,
  itemSizes: [] as any,
  allItems: [] as any,
  allItemsLoading: false,
};

export type ItemManagementState = Readonly<typeof initialState>;

const itemManagement = (
  state = initialState,
  action: any
): ItemManagementState => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ITEMS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: null,
        items: payload.items,
        totalCount: payload.totalCount,
      };
    case FETCH_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        fetchErrorMessage: payload.fetchErrorMessage,
        items: null,
      };
    case CREATE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case CREATE_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case OVERWRITE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case OVERWRITE_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        successMessage: payload.successMessage,
      };
    case UPDATE_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };

    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        successMessage: payload.successMessage,
      };
    case DELETE_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: payload.errorMessage,
        successMessage: null,
      };
    case CLEAR_ITEM_MSG:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
        fetchErrorMessage: null,
      };
    case CLEAR_ITEM_FETCH_ERROR_MSG:
      return {
        ...state,
        fetchErrorMessage: null,
      };
    case FETCH_ITEM_SIZE_TYPES:
      return {
        ...state,
        itemSizes: payload.itemSizes,
      };
    case FETCH_ITEM_SIZE_TYPES_FAIL:
      return {
        ...state,
        itemSizes: [],
      };
    case FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
      };
    case FETCH_CATEGORIES_FAIL:
      return {
        ...state,
        categories: [],
      };
    case FETCH_CATALOGUE_TYPES_SUCCESS:
      return {
        ...state,
        catalogues: payload.catalogues,
      };
    case FETCH_CATALOGUE_TYPES_FAIL:
      return {
        ...state,
        catalogues: [],
      };

    case FETCH_ALL_ITEMS_START:
      return {
        ...state,
        allItemsLoading: true,
      };
    case FETCH_ALL_ITEMS_SUCCESS:
      return {
        ...state,
        allItemsLoading: false,
        allItems: payload.items,
      };
    case FETCH_ALL_ITEMS_FAIL:
      return {
        ...state,
        allItemsLoading: false,
        errorMessage: payload.error,
      };
    default:
      return state;
  }
};
export default itemManagement;
