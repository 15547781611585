import React, { useState, useEffect } from "react";
import { Modal, Radio } from "antd";

type Item = {
    id: number;
    name: string;
};

interface ConflictModalProps {
    visible: boolean;
    onCancel: () => void;
    onConfirm: (selectedItemId: number) => void;
    items: Item[];
    newItem: Item;
}

const ConflictModal = ({ visible, onCancel, onConfirm, items, newItem }: ConflictModalProps) => {
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    useEffect(() => {
        if (items.length > 0) {
            setSelectedItemId(items[0].id); // Default to the first item's ID
        }
    }, [items]);

    const handleConfirm = () => {
        if (selectedItemId !== null) {
            onConfirm(selectedItemId);
        }
    };

    return (
        <Modal
            visible={visible}
            title="Matching Deleted Items"
            onCancel={onCancel}
            onOk={handleConfirm}
            okText="Yes, Proceed"
            cancelText="No, Create a New Item"
        >
            <div>
                <p>
                    The new item <b>{`${newItem.name}`}</b> seems to resemble:
                </p>
                <Radio.Group
                    value={selectedItemId}
                    onChange={(e) => setSelectedItemId(e.target.value)}
                >
                    {items.map((item) => (
                        <div key={item.id} >
                            <Radio value={item.id}>
                               <b>{item.name}</b>
                            </Radio>
                        </div>

                    ))}
                </Radio.Group>
                <p>
                    Would you like to re-map asset data from the old item to this one?
                </p>
                <p>
                    Note: This will move retired asset data into staging status.
                </p>
            </div>
        </Modal>
    );
};

export default ConflictModal;
