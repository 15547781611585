export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAIL = "FETCH_ITEMS_FAIL";
export const CREATE_ITEM_SUCCESS = "CREATE_ITEM_SUCCESS";
export const CREATE_ITEM_FAIL = "CREATE_ITEM_FAIL";
export const OVERWRITE_ITEM_SUCCESS = "OVERWRITE_ITEM_SUCCESS";
export const OVERWRITE_ITEM_FAIL = "OVERWRITE_ITEM_FAIL";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_FAIL = "UPDATE_ITEM_FAIL";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const CLEAR_ITEM_MSG = "CLEAR_ITEM_MSG";
export const CLEAR_ITEM_FETCH_ERROR_MSG = "CLEAR_ITEM_FETCH_ERROR_MSG";
export const FETCH_ITEMS_START = "FETCH_ITEMS_START";
export const FETCH_CATALOGUE_TYPES_SUCCESS = "FETCH_CATALOGUE_TYPES_SUCCESS";
export const FETCH_CATALOGUE_TYPES_FAIL = "FETCH_CATALOGUE_TYPES_FAIL";
export const FETCH_ITEM_SIZE_TYPES = "FETCH_ITEM_SIZE_TYPES";
export const FETCH_ITEM_SIZE_TYPES_FAIL = "FETCH_ITEM_SIZE_TYPES_FAIL";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAIL = "FETCH_CATEGORIES_FAIL";

export const FETCH_ALL_ITEMS_START = "FETCH_ALL_ITEMS_START";
export const FETCH_ALL_ITEMS_SUCCESS = "FETCH_ALL_ITEMS_SUCCESS";
export const FETCH_ALL_ITEMS_FAIL = "FETCH_ALL_ITEMS_FAIL";
